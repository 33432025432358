<!-- eslint-disable vue/no-parsing-error -->
<template>
	<div class="goods_unit1 main-page">
		<div class="show_mb goods_unit">
			<div class="unit-left">
				<img :src="prodetail.goods_detail.img_minshrink" alt="">
				<div class="lname">{{prodetail.goods_detail.goods_name}}</div>
			</div>
			<div class="goods_unit-box">
				<div class="unit-cat">
					功能分类： <span v-for="(item,index) in prodetail.goods_detail.cat" :key="index">{{item.title}}{{index+1==prodetail.goods_detail.cat.length?'':'、'}}</span>
				</div>
				<div class="eng_desc">{{prodetail.goods_detail.eng_desc}}</div>
				<div class="goods_unit-title">
					<div class="goods_unit-nums" style="text-align: left !important;">
						<div>每份含成分（{{goods_num}}颗）</div>
						<div>Amount Per Serving</div>
					</div>
					<div class="goods_unit-nums">
						<div>每日营养摄入量百分比</div>
						<div>% Daily Value</div>
					</div>
				</div>
				<div class="goods_unit-line" v-for="(ingredientsItem,index) in prodetail.goods_detail.goods_unit" :key="index">
					<!-- 有分组的情况 -->
					<div v-if="ingredientsItem.group_en_name">
						<div class="ingredients_info">
							<div class="ingredient_item_one">{{ingredientsItem.group_en_name}}</div>
							<div class="ingredient_item_two">{{ingredientsItem.group_unit_size}}{{ingredientsItem.group_unit}}</div>
							<div class="ingredient_item_three">{{ingredientsItem.group_unit_percentage}}</div>
						</div>
						<div class="ingredients_group">
							<div class="ingredient_group_en"><div v-for="(item,index) in ingredientsItem.ingredients" :key="index">{{item.unit_eng}}</div></div>
							<div class="ingredient_group_zh">{{ingredientsItem.group_zh_name}}<span v-for="(item,index) in ingredientsItem.ingredients" :key="index">{{item.unit_name}}{{index<(ingredientsItem.ingredients).length-1?',':''}}</span></div>
						</div>
					</div>
					<!-- 没有分组的情况 -->
					<div v-else>
						<div class="ingredients_info ingredients_info_single" v-for="(item,index) in ingredientsItem.ingredients" :key="index">
							<div class="ingredient_item_one">{{item.unit_eng}} {{item.unit_name}}</div>
							<div class="ingredient_item_two">{{item.unit_size}}{{item.unit}}</div>
							<div class="ingredient_item_three">{{item.unit_percentage}}</div>
						</div>
					</div>
				</div>
				<div class="goods_unit-line bborder" v-if="prodetail.goods_detail.other_component">
					<!-- <div class="goods_unit-name"> -->
						<div>其他成分：{{prodetail.goods_detail.other_component}}</div>
					<!-- </div> -->
				</div>
				<div class="ingredient_suffix lasttxt">{{prodetail.goods_detail.ingredient_suffix}}</div>
				<div class="daynum1 lasttxt">* Percent Daily Values are based on a 2,000 calories diet
                    每日营养摄入量百分比按日饮食摄入2000卡路里计算</div>
				<div class="daynum2 lasttxt">** Daily Value not established 未规定每日摄入量</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default{
		name:'goods_unit',
		props: {
			id: {
				type: Number,
				default: null
			},
			goods_num: {
				type: Number,
				default: null
			}
		},
		components:{
		},
		data:()=>({
			info: {},
			goodslist: [],
			prodetail: {
				goods_detail: {}
			}
		}),
		mounted() {
			var that = this
			// var id = this.$route.query.id

			this.$ajax.post(this.API.api.apiurl.getGoodsInfo, {
					goods_id: this.id,
					goods_num: this.goods_num
				})
				.then(res => {
					var data = res.data.data
					if (res.data.code == 0) {
						that.prodetail = data
					} else {
						that.$Message.info('错误：' + res.data.message)
					}
				})
				.catch(error => {
					that.$Message.info(error)
				})
		},
		methods:{
		},
	}
</script>

<style>
	.goods_unit1 .show_mb {
		margin: 0 auto;
		font-size: 12px;
	}
	.goods_unit{
		width: 100%;
		height: auto;
		/* margin: 0.2rem auto 0.8rem auto; */
		display: flex;
		justify-content: space-between;
	}
	.goods_unit1{
		width: calc(100% - 3rem);
		overflow-y: auto;
		margin: 0 1.5rem 25px;
	}
	.unit-left{
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 3.2rem;
		height: auto;
		background: #DCE4E3;
		padding: 0 0.2rem;
		border-radius: 5px;
	}
	.unit-left img{
		width: 2.8rem;
		height: 2.8rem;
		margin-top: 0.8rem;
	}
	.lname{
		width: 100%;
		height: auto;
		font-size: 0.26rem;
		font-size: 12px;
		margin-top: 0.2rem;
		text-align: center;
	}
	.goods_unit .goods_unit-box{
		flex: 1;
		margin-left: 10px;
	}
	.goods_unit-title{
		font-size: 12px;
		width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    padding: 0.4rem 0;
    border-bottom: 0.02rem solid #000;
    font-weight: 700;
    color: #1a1a1a;
	}

	.goods_unit-nums {
    text-align: right;
		width: 130px;
}
	.goods_unit-line {
		width: 100%;
    height: auto;
    line-height: 20px;
    font-size: .26rem;
    font-size: 12px;
    /* display: flex;
    justify-content: space-between; */
    border-bottom: 0.02rem solid #000;
    color: #1a1a1a;
    padding: 0.2rem 0;
	}

	.goods_unit .goods_unit-name {
    width: 56%;
    font-size: .28rem;
    font-size: 12px;
    text-align: left;
}
.goods_unit-name {
    width: 56%;
    height: auto;
    line-height: 1rem;
    font-size: .28rem;
    color: #1a1a1a;
    text-align: left;
}

.goods_unit-line .goods_unit-name div {
    font-size: 12px!important;
		box-sizing: border-box;
}

.goods_unit-line .goods_unit-name p {
	margin: 0;
	padding: 0;
}

.goods_unit .goods_unit-num {
    font-size: 12px;
}

.goods_unit-num {
    height: auto;
}
.goods_unit-num, .goods_unit-x {
    width: 22%;
    text-align: right;
}


.goods_unit-num div {
    width: 100%;
    text-align: right;
}

	.bborder {
		border-bottom: 0.04rem solid #000;
	}

	.goods_unit-title .goods_unit-name{
		width: 50%;
	}
	.goods_unit .goods_unit-num{
		font-size: 12px;
	}
	.goods_unit-line{
		text-align: left;
	}

	.lasttxt, .unit-cat {
    width: 100%;
    text-align: left;
    color: #1a1a1a;
}

.lasttxt {
    font-size: .2rem;
    font-size: 12px;
    margin: 0.17rem 0;
}
	.unit-cat{
		width: 100%;
		height: auto;
		font-size: 0.3rem;
		font-size: 12px;
		color: #1A1A1A;
		text-align: left;
		font-weight: bold;
		line-height: 20px;
	}
	.unit-cat span{
		color: #666666;
		font-weight: 350;
	}
	.eng_desc{
		width: 100%;
		height: auto;
		font-size: 0.2rem;
		font-size: 12px;
		color: #1A1A1A;
		border-bottom: 0.08rem solid #000000;
		/* margin-top: 0.3rem; */
		padding: 0.4rem 0;
		text-align: left;
	}
	.goods_unit1 .detail_pc{
		width: 100%;
		margin: 0 auto;
	}

	/* 优化的标签样式 */
	.ingredients_info {
		display: flex;
		justify-content: space-between;
	}

	.ingredients_info_single {
		border-bottom: 0.04rem solid #000;
		padding: 0.2rem 0;
	}

	.ingredients_info_single:first-child {
    padding-top: 0;
	}

	.ingredients_info_single:last-child {
		border: none;
    padding-bottom: 0;
	}

	.ingredients_group {
	max-width: 70%;
  word-break: break-word;
	}

	.ingredient_group_en {
		margin-left: 10px;
	}

	.ingredient_item_one {
  flex: 1;
}

.ingredient_item_two {
  width: 4rem;
  text-align: right;
  margin-right: 1rem;
}

.ingredient_item_three {
  width: 3rem;
  text-align: right;
}
</style>
