// 正式环境
const iway = 'admin'
const ip = 'https://api.xiaojudai.cn/api'
// 测试环境
// const iway = 'test'
//  const ip = 'https://testapi.xiaojudai.cn/api'
// 开发环境
// const iway = 'dev'
// const ip = 'https://devapi.xiaojudai.cn/api'
const api = {
	apiurl:{
		way:iway,
		login:ip+'',
		getOrderCart:ip+'/scan/getOrderCart',//获取首页内容
		getDetail:ip+'/scan/getDetail',//获取首页内容
		getGoodsInfo: `${ip}/goods/getGoodsInfo`
	}
}
export default{
	api:api
}
