<template>
	<div class="prolist">
		<div class="head_img">
			<img class="img-css" src="https://nutripacks-static.oss-cn-shenzhen.aliyuncs.com/static/h5/img/bg_header_fix.jpg" alt="">
			<div class="user">
				<div class="user_name">{{goodsinfo.cart_name}}</div>
				<div class="user_id">{{goodsinfo.order_no}}</div>
				<div class="judai_info">
					<div class="item" style="">
						<div class="item_title">补剂</div>
						<div class="item_num">{{goodsinfo.cat_num}}</div>
					</div>
					<div class="item_border"></div>
					<div class="item">
						<div class="item_title">颗数</div>
						<div class="item_num">{{goodsinfo.med_num}}</div>
					</div>
					<div class="item_border"></div>
					<div class="item">
						<div class="item_title_date">最佳服用期至</div>
						<div class="item_date">{{goodsinfo.expire_at}}</div>
					</div>
				</div>
			</div>
		</div>
		<div class="plan">
			<div class="plan_zh">每日补剂方案</div>
			<div class="plan_en">YOUR DAILY PLAN</div>
		</div>
		<div class="plan_boder"></div>
		<div class="plan_items">
			<a :href="'/i/d?k='+encodeURIComponent(kid)+'&goods_id='+item.goods_id" v-for="(item,index) in goodsinfo.order_goods" :key="index">
				<div class="plan_item" style="margin-left: 3rem;margin-right: 1rem;">
					<div>
						<!-- 药品图片 -->
						<img class="plan_item_img" :src="item.index_img"
						alt="">
					</div>
					<div class="plan_item_name">{{item.goods_name}}</div>
					<div class="plan_item_num">x{{item.goods_num}}</div>
					<div class="plan_item_btn">查看品质</div>
				</div>
			</a>
		</div>
		<div class="caution">
			<div class="caution_zh">注意事项</div>
			<div class="caution_en">CAUTIONS</div>
		</div>
		<div class="caution_boder"></div>
		<div class="caution_items">
			<div class="caution_item" v-for="(item,index) in goodsinfo.note" v-html="item"  :key="index"></div> 
		</div>
		<div class="instructions">
			<div class="instructions_zh">服用指导</div>
			<div class="instructions_en">INSTRUCTIONS</div>
		</div>
		<div class="instructions_boder"></div>
		<div class="instructions_items">
			<div class="instructions_item" v-for="(item,index) in goodsinfo.guidance" :key="index">
				<div class="instructions_item_img img_one">
					<img :src="item.icon" alt="">
				</div>
				<div class="instructions_item_title" v-html="item.text"></div>
			</div>
		</div>

		<div class="instructions">
			<div class="instructions_zh">营养标签</div>
			<div class="instructions_en">SUPPLEMENT FACTS</div>
		</div>
		<div class="instructions_boder" style="margin-bottom: 25px"></div>
		<NutritionLabels v-for="item in goodsinfo.order_goods" :key="item.goods_id" :id="item.goods_id" :goods_num="item.goods_num"/>

		<div v-if="!isClientB" class="question">
			<span>对补剂方案有疑问？</span>
			<div>专属营养顾问VC 免费解答</div>
		</div>
		<div v-if="!isClientB" class="wx">
			<img class="wx_img" src="https://nutripacks-static.oss-cn-shenzhen.aliyuncs.com/static/h5/img/scan.jpg" alt="">
			<div class="wx_title">
				微信内长按扫码
			</div>
		</div>

		<div v-if="!isClientB" class="fixed-bottom-button" @click="navigateToMiniProgram">
			<img src="https://nutripacks-static.oss-cn-shenzhen.aliyuncs.com/static/dist/other/remind-icon-new.png" alt="clock-icon">
			<span>开启服用提醒</span>
			<div class="button-tips">
				<img src="https://nutripacks-static.oss-cn-shenzhen.aliyuncs.com/static/dist/other/remind-tips-new.png" alt="tips-background">
				<span>常常忘记吃补剂？</span>
			</div>
		</div>
	</div>
</template>

<script>
	// import foot from '../components/footer.vue'
	import { Toast } from 'vant'
	import 'vant/lib/toast/style';
	import NutritionLabels from '../components/NutritionLabels.vue'

	export default {
		name: 'productList',
		components: {
			NutritionLabels
		},
		data: () => ({
			info: '',
			tid: 'all',
			goodsinfo:'',
			kid:'',
			goodslist: '',
			puttop: false,
			show: false,
			isClientB: false
		}),
		mounted() {
			var that = this
			this.kid = that.$route.query.k

			setTimeout(function() {
				that.show = true
			}, 600)
			this.$ajax.post(this.API.api.apiurl.getOrderCart, {
					k: that.$route.query.k
				})
				.then(res => {
					// console.log(res)
					var data = res.data
					if (data.code == 0) {
						that.goodsinfo = data.data

						if (that.goodsinfo.buy_days && that.goodsinfo.buy_days === 14) {
							Toast('14天体验装暂时无法参与天天打卡')
						}

						this.isClientB = that.goodsinfo.order_no.includes('BJD')
						// var list = data.data
						// list.sort(that.compare('id'))
						// that.goodslist = list
					} else {
						alert('错误：' + data.msg)
					}
				})
				.catch(error => {
					alert(error)
				})
		},
		methods: {
			compare(key) {
				return function(o1, o2) {
					var a = o1[key],
						b = o2[key];
					return a - b
				}
			},
			handleScroll() {
				//方法一
				//var top = Math.floor(document.body.scrollTop || document.documentElement.scrollTop || window.pageYOffset)
				//console.log(top)

				//方法二
				//如果获取不到高度可换成
				// this.nextTick(()=>{
				var top = document.body.scrollTop || document.documentElement.scrollTop || window.pageYOffset
				if (top > 350) {
					this.puttop = true
				} else {
					this.puttop = false
				}
				// })
			},
			goqus() {
				this.$router.push({
					path: 'home'
				})
			},
			godetail(id) {
				this.$router.push({
					path: 'product_detail',
					query: {
						id: id
					}
				})
			},
			navigateToMiniProgram() {
				/* eslint-disable */
				wx.miniProgram.navigateTo({
					url: '/other/pages/chooseRemindTime/index?checkInEveryDay=1',
				})
			}
		},
	}
</script>

<style scoped>
.prolist .fixed-bottom-button {
	position: fixed;
	bottom: 57px;
	left: 50%;
	transform: translateX(-50%);
	display: flex;
	align-items: center;
	justify-content: center;
	width: 209px;
	height: 44px;
	box-shadow: 0 10px 15px -5px rgba(0,0,0,0.1000);
	border: 1px solid #000000;
	border-radius: 55px;
	z-index: 3;
	background-color: #FFFFFF;
}

.prolist .fixed-bottom-button > img {
	width: 17px;
	height: auto;
	margin-right: 10px;
}

.prolist .fixed-bottom-button > span {
	font-size: 14px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 500;
	color: #141414;
}

.prolist .button-tips {
	position: absolute;
	right: -40px;
	top: -26px;
}

.prolist .button-tips > img {
	width: 120px;
	height: 44px;
}

.prolist .button-tips > span {
	position: absolute;
	left: 14px;
	top: 10px;
	font-size: 12px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #fff;
}
</style>
