// import 'babel-polyfill'
// import promise from 'es6-promise'
// promise.polyfill()
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import api from './util/api.js'
import axios from './util/axios.js'
import qs from 'qs'
import './util/rem.js'

Vue.prototype.$ajax=axios 
Vue.prototype.$qs=qs 
Vue.prototype.API = api
Vue.prototype.showalert = false
Vue.mainshowalert = false

// import 'lib-flexible/flexible.js'
Vue.config.productionTip = false
new Vue({
	// Vuetify,
	router,
  render: h => h(App),
}).$mount('#app')
