<template>
	<div id="app" ref="detailmain">
		<div class="loading-container" v-if="isLoading">
			<van-loading />
		</div>
		<router-view v-else />
	</div>
</template>

<script>
import Loading from 'vant/lib/loading';
import 'vant/lib/loading/style';

	export default {
		name: 'App',
		components: {
			'van-loading': Loading
		},
		data: () => ({
			path: '',
			bottomshow: false,
			showwei: true,
			showit: false,
			islogin: false,
			isLoading: true
		}),
		watch: {
			'$route': {
				immediate: true,
				handler(to) { //监听路由是否变化
					window.scrollTo(0, 0)
					var that = this
					that.showit = false

					if (to.query.k === 'k5tpcIWdb2pq') {
						window.location.href = 'https://mp.weixin.qq.com/s?__biz=MzU4NTc2OTQxMA==&mid=2247507498&idx=1&sn=8fd2a7d5b13a2521246efd92c6a72db3&chksm=fd871687caf09f91fc2bbc9ae630a0025c10b0e8ad84c42c8c59a67a78df9da31c96f372611f#rd'

						return
					}

					this.isLoading = false
					// setTimeout(function(){
					// 	that.showit = true
					// },1000)
				}
			}
		},
		created() {
			// window.addEventListener('beforeunload', e => this.test(e))
		},
		destroyed() {},
		mounted() {

		},
		methods: {
			_isMobile() {
				let flag = navigator.userAgent.match(
					/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
				)
				return flag;
			},
			formatDate(now) {
				now = new Date(now)
				var year = now.getFullYear();
				var month = now.getMonth() + 1;
				var date = now.getDate();
				var hour = now.getHours();
				var minute = now.getMinutes();
				var second = now.getSeconds();
				return year + "-" + month + "-" + date + " " + hour + ":" + minute + ":" + second;
			}
		}
	}
</script>

<style>

	.loading-container {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100vh;
	}

	.prodetail-imgs {
		width: 100%;
		height: auto;
		margin-top: 1.2rem;
	}

	.prodetail-imgs img {
		width: 100%;
		height: auto;
	}

	.detail-pro-title {
		width: 100%;
		height: auto;
		line-height: 2.2rem;
		font-size: 1.5rem;
		font-weight: bold;
		text-align: center;
		color: #000000;
		margin-top: 1.9rem;
		margin-bottom: 0.7rem;
	}

	.function-list {
		width: 100%;
		height: auto;
	}

	.function-box {
		width: 86%;
		height: 3.4rem;
		display: flex;
		justify-content: space-between;
		margin: 0 auto;
		margin-top: 1.6rem;
	}

	.function-box img {
		width: 3.4rem;
		height: 3.4rem;
		border-radius: 3.4rem;
	}

	.function-txts {
		width: calc(100% - 4rem);
		height: 1.7rem;
	}

	.function-txts .function-title {
		width: 100%;
		height: 1.6rem;
		line-height: 1.6rem;
		font-size: 0.74rem;
		font-weight: bold;
		border-bottom: 0.02rem solid #000000;
		text-align: left;
		color: #000000;
	}

	.function-txts .function-msg {
		width: 100%;
		height: 1.6rem;
		line-height: 1.6rem;
		font-size: 0.64rem;
		text-align: left;
		color: #000000;
	}

	.advantage-list {
		width: 100%;
		height: auto;
		margin-top: 3.6rem;
	}

	.advantage-list .advantage-box {
		width: 86%;
		height: auto;
		margin: 0 auto;
		margin-bottom: 2rem;
	}

	.advantage-box .advantage-title {
		width: 100%;
		height: 1rem;
		line-height: 1rem;
		font-weight: bold;
		color: #000000;
		font-size: 1rem;
		text-align: left;
	}

	.advantage-txt {
		width: 100%;
		height: auto;
		display: flex;
		justify-content: space-between;
		margin-top: 1rem;
	}

	.advantage-txt .advantage-border {
		width: 1.7rem;
		height: 0.2rem;
		/* background: #C25E4D; */
		border-bottom: 0.25rem solid #C25E4D;
		margin-top: 0.17rem;
	}

	.advantage-txt .advantage-msg {
		width: calc(100% - 3.3rem);
		line-height: 1.35rem;
		font-size: 0.78rem;
		color: #333333;
		text-align: left;
	}
</style>
