<!-- eslint-disable vue/no-parsing-error -->
<template>
	<div class="prodetail">
		<div class="head_img">
			<div class="head_img_box" style="width: 100%;height: 20rem;overflow: hidden;">
				<img class="img-css" :src="bimg" alt="">
			</div>
			<div class="product">
				<div class="product_name">{{prodetail.goods_detail.goods_name}}</div>
				<div class="product_name_en">{{prodetail.goods_detail.eng_name}}</div>
				<div class="product_info">
					<div class="product_info_num">每日{{prodetail.goods_detail.goods_num}}颗</div>
					<div class="product_info_effect">
						<div class="effect_item" v-for="(item,index) in prodetail.goods_detail.cat" :key="index">
							<div class="">
								<img class="effect_item_icon" :src="item.url" alt="">
							</div>
							<div class="effect_item_name">{{item.title}}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="detail-pro-title">
			<div class="pro-title1">NUTRIPACKS</div>
			<div class="pro-title2">{{prodetail.goods_detail.goods_name}}的优势</div>
		</div>
		<div class="prodetail-imgs">
			<img :src="prodetail.goods_img_text.img4" alt="">
		</div>
		<div class="function-list">
			<div class="function-box" v-for="(item,index) in prodetail.goods_img_text.function" :key="index">
				<img :src="item.img" alt="">
				<div class="function-txts">
					<div class="function-title">{{item.title}}</div>
					<div class="function-msg"><span v-for="(items,indexs) in item.label" :key="indexs">{{items}}{{indexs+1==item.label.length?'':' | '}}</span></div>
				</div>
			</div>
		</div>
		<div class="advantage-list">
			<div class="advantage-box" :class="index%3==2?'noright':''"  v-for="(item,index) in prodetail.goods_img_text.advantage" :key="index">
				<div class="advantage-title">{{item.title}}</div>
				<div class="advantage-txt">
					<div class="advantage-border"></div>
					<div class="advantage-msg fontsize">{{item.describe}}</div>
				</div>
			</div>
		</div>
		<div class="prodetail-imgs">
			<img v-if="prodetail.goods_img_text.miniprogram_img_config.usa_production_img_url" :src="prodetail.goods_img_text.miniprogram_img_config.usa_production_img_url" alt="">
		</div>
		<div class="facts">
			<div class="facts_zh">营养标签</div>
			<div class="facts_en">SUPPLEMENT FACTS</div>
		</div>
		<div class="facts_boder"></div>
		<div class="facts_form">
			<div class="facts_form_title">
				<div class="form_title_left">每份含成分<span>Amount Per Serving</span></div>
				<div class="form_title_right">每日营养摄入量百分比<span>% Daily Value</span></div>
			</div>
			<div class="ingredients_item" v-for="(ingredientsItem,index) in prodetail.goods_detail.goods_unit" :key="index">
				<div class="item_boder"></div>
				<!-- 有分组的情况 -->
					<div v-if="ingredientsItem.group_en_name">
						<div class="ingredients_info">
							<div class="ingredient_item_one">{{ingredientsItem.group_en_name}}</div>
							<div class="ingredient_item_two">{{ingredientsItem.group_unit_size}}{{ingredientsItem.group_unit}}</div>
							<div class="ingredient_item_three">{{ingredientsItem.group_unit_percentage}}</div>
						</div>
						<div class="ingredients_group">
							<div class="ingredient_group_en"><div v-for="(item,index) in ingredientsItem.ingredients" :key="index">{{item.unit_eng}}</div></div>
							<div class="ingredient_group_zh">{{ingredientsItem.group_zh_name}}<span v-for="(item,index) in ingredientsItem.ingredients" :key="index">{{item.unit_name}}{{ index<(ingredientsItem.ingredients).length-1?',':''}}</span></div>
						</div>
					</div>
					<!-- 没有分组的情况 -->
					<div v-else>
						<div class="ingredients_info ingredients_info_single" v-for="(item,index) in ingredientsItem.ingredients" :key="index">
							<div class="ingredient_item_one">{{item.unit_eng}} {{item.unit_name}}</div>
							<div class="ingredient_item_two">{{item.unit_size}}{{item.unit}}</div>
							<div class="ingredient_item_three">{{item.unit_percentage}}</div>
						</div>
					</div>
			</div>
			<div class="item_boder" style="margin: 0.5rem 1.5rem"></div>
			<div class="facts_form_footer">
				<div class="form_footer"><span>其他成分：</span>{{prodetail.goods_detail.other_component}}</div>
			</div>
			<div class="title_boder"></div>
		</div>
		<div @click="backurl" class="back">返回</div>
	</div>
</template>

<script>
	import "../../public/css/detail.css"
	// import foot from '../components/footer.vue'
	export default {
		name: 'productDetail',
		components: {
			// foot
		},
		data: () => ({
			info: '',
			gid: '',
			prodetail: '',
			bimg: ''
		}),
		mounted() {
			var that = this
			that.gid = this.$route.query.k
			this.$ajax.post(this.API.api.apiurl.getGoodsInfo, {
					k: that.$route.query.k,
					goods_id: Number(that.$route.query.goods_id)
				})
				.then(res => {
					var data = res.data
					if (data.code == 0) {
						// var goods_detail = data.data.goods_detail
						// var sprice = goods_detail.shop_price
						// var dayprice = Number(sprice * goods_detail.day_use).toFixed(2)
						// goods_detail.dayprice = dayprice
						// data.data.goods_detail = goods_detail
						var iu = data.data.goods_img_text.big_img
						that.bimg = iu
						that.prodetail = data.data
						console.log(that.prodetail.goods_detail.goods_unit)
					} else {
						alert('错误：' + data.msg)
					}
				})
				.catch(error => {
					alert(error)
				})

			// if (window.history && window.history.pushState) {
			// 	history.pushState(null, null, document.URL);
			// 	window.addEventListener('popstate', this.goBack, false);
			// }
		},
		methods: {
			backurl(){
				this.$router.go(-1);
			},
			goBack() {
				console.log('123')
				if (this.$route.query.way == 'report') {
					this.$router.go(-1);
				}
				// sessionStorage.clear();
				// window.history.back();
				// history.pushState(null, null, document.URL);
			},
			gohome() {
				this.$router.push({
					path: 'pro_detail'
				})
			},
			gounitdetail(id) {
				// window.location.href= 'https://store.nutri-packs.cn/goods_unit?id='+id
				this.$router.push({
					path: 'goods_unit',
					query: {
						id: id
					}
				})
			},
			goreportdetail(id) {
				// window.location.href= 'https://store.nutri-packs.cn/research?id='+id
				this.$router.push({
					path: 'research',
					query: {
						id: id
					}
				})
			}
		},
	}
</script>

<style>

</style>
