import Vue from "vue";
import Router from "vue-router";
import productDetail from "@/pages/pro_detail.vue";
import productList from "@/pages/prolist.vue";

Vue.use(Router)

// export default new Router({
  const routes  = [{
			path: "/",
			name: 'productList',
			component: productList,
			meta: {}
		},{
			path: "/i/d",
			name: 'productDetail',
			component: productDetail,
			meta: {}
		},{
			path: "/i",
			name: 'productList',
			component: productList,
			meta: {}
		}]
// })
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const router = new Router({
    mode: 'history',
  routes: routes      //注意是routes而不是routers,坑就在这里
})
router.beforeEach((to, from, next) => {
		next()
})
export default router