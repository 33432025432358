import axios from 'axios'
import {md5} from './md5.js'

// 对象字母升序排序
const objSort = (obj) => {
  let newkey = Object.keys(obj).sort()
  let newObj = {}

  for (let i = 0; i < newkey.length; i++) {
    if (obj[newkey[i]] || obj[newkey[i]] === 0) {
      newObj[newkey[i]] = obj[newkey[i]]
    }
  }

  // 返回排好序的新对象
  return newObj
}
const request = axios.create({
  baseURL: '',
  headers: {
    'content-type': 'application/json;charset=UTF-8',
  },
})

request.interceptors.request.use(
  (config) => {
		let options = config

    options.data = options.data ? options.data : {}
      options.method = options.method ? options.method : 'post'
			
      // 请求时间戳
      options.data.timestamp = parseInt(new Date().getTime() / 1000)
    
      /* 接口签名计算开始 */
      // 系统 key 值
      options.data.key = '74260f176fef3fe877351cf446c981c8'
      // 参数名字母升序排序
      options.data = objSort(options.data)
    
      // 参数字符串拼接
      let formatData = {}
      let formatString = ''
			
      Object.keys(options.data).forEach(name => {
				if (typeof options.data[name] == 'object') {
					formatData[name] = JSON.stringify(options.data[name])
				} else {
					formatData[name] = options.data[name]
				}
				
				formatString += `${name}=${formatData[name]}&`
			})

      formatString = formatString.substring(0, formatString.length - 1)
			
			// console.log(formatString, 'formatString')
      // md5转大写
      options.data.sign = md5(formatString).toUpperCase()
			delete options.data['key']
			
      /* 接口签名计算结束 */

    return options
  },
  (error) => Promise.reject(error)
)

request.interceptors.response.use(
  function (result) {
		const { status } = result
		
    if (status >= 200 && status < 300) {
			return Promise.resolve(result)
    } else {
			return Promise.reject(result)
		}
  },
  function (error) {
    // tips('服务器错误')
    return Promise.reject(error)
  }
)

export default request
